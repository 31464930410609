export const typeLocauxExploitantList = [
  {
    "text": "Propriétaire des locaux​",
    "value": "PROPRIETARY"
  },
  {
    "text": "Locataire des locaux au titre d’un bail commercial​",
    "value": "BAILCOM"
  },
  {
    "text": "Locataire au titre d’un autre type de bail​​",
    "value": "AUTREBAIL"
  }
]
