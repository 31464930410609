<template>
  <div class="EditOffreCession" >
    <div class="container">
      <h1 class="gavc-h1 text-center mb-4">Mon annonce</h1>

      <h2 class="gavc-h3"> Aperçu de votre annonce </h2>
      <annonceItem
        :annonce="annonce"
      />

      <div
        v-if="annonce.is_published"
        class="mt-4"
      >
        <p class="gavc-chapo" >
          <span
            v-if="annonce.is_validated"
            class="text-success"
          >
            <i class="gavc-icon gavc-icon-check-circle"></i> Félicitations, votre annonce est validée et publiée
          </span>
          <span
            v-else
            class="gavc-text-martinique"
          >
            <i class="gavc-icon gavc-icon-hourglass"></i> Votre annonce est en cours de validation par nos équipes
          </span>
        </p>
        <div class="row">
          <div class="col-12 text-center text-lg-right">
            <button
              class="gavc-btn"
              @click="publishAnnonce(true)"
              :disabled="isInvalidAnnonce">
                Publier mon annonce
            </button>
            <button
              class="gavc-btn gavc-btn--bone"
              @click="publishAnnonce(false)">
                Retirer mon annonce
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mt-4"
      >
        <p class="gavc-chapo gavc-text-rose" >
          <i class="gavc-icon gavc-icon-cross"></i> Votre annonce n'est pas encore publiée
        </p>
        <div class="row">
          <div class="col-12 text-center text-lg-right">
            <button
              class="gavc-btn float-right "
              @click="publishAnnonce(true)"
              :disabled="isInvalidAnnonce">
                Publier mon annonce
            </button>
          </div>
        </div>
      </div>

      <p v-if="!annonce.is_annonce_completed">
          Vous devez <strong>compléter les informations ci-dessous </strong> avant de pouvoir publier votre annonce
      </p>

      <h2 class="gavc-h3 mt-4"> Modifier votre annonce </h2>

      <p class="gavc-chapo">Informations sur l'entreprise cédée :</p>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': modifyEntrepriseCedeeName && $v.modifyEntrepriseCedeeName.$dirty ? $v.modifyEntrepriseCedeeName.$error : null }"
      >
        <label class="gavc-label" for="edit-modifyEntrepriseCedeeName-group">
            La raison sociale de votre entreprise est :
            <span class="gavc-required"> * </span>
            <i
              v-b-tooltip.hover.right="{ variant: 'light' }"
              title="La raison sociale correspond au nom officiel que vous avez déclaré lors de la création de votre entreprise. Elle figure sur son extrait d’immatriculation et dans les statuts de votre société."
              class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
            </i>
        </label>
        <input
          class="gavc-input"
          id="edit-modifyEntrepriseCedeeName-group"
          maxlength="250"
          placeholder="Raison sociale"
          @input="$v.modifyEntrepriseCedeeName.$touch()"
          type="text"
          v-model="modifyEntrepriseCedeeName"
        >
        <inputErrorValidationMessage
          v-if="modifyEntrepriseCedeeName && $v.modifyEntrepriseCedeeName.$invalid"
          message="La raison sociale de votre entreprise doit comporter entre 2 et 250 caractères"
        />
      </div>

      <div
         class="gavc-field"
         v-bind:class="{ 'is-error': modifyEntrepriseCedeeFormeSociale && $v.modifyEntrepriseCedeeFormeSociale.$dirty ? $v.modifyEntrepriseCedeeFormeSociale.$error : null }"
       >
        <label class="gavc-label" for="edit-entreprise_cedee-modifyEntrepriseCedeeFormeSociale-input">
          La forme sociale de l'entreprise est :
          <span class="gavc-required"> * </span>
        </label>
        <select
          class="gavc-select"
          v-model="modifyEntrepriseCedeeFormeSociale"
          name="edit-entreprise_cedee-modifyEntrepriseCedeeFormeSociale-input"
        >
          <option v-for="option in formeSocialeOptions" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
        <i
          v-b-tooltip.hover.right="{ variant: 'light' }"
          title="Si vous cherchez la forme sociale / statut juridique de votre entreprise, consultez Societe.com avec votre numéro de
                 SIREN, de SIRET ou en tapant le nom de votre entreprise."
          class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
        </i>
      </div>

      <div
         class="gavc-field"
         v-bind:class="{ 'is-error': modifyEntrepriseCedeeProcedureCollective && $v.modifyEntrepriseCedeeProcedureCollective.$dirty ? $v.modifyEntrepriseCedeeProcedureCollective.$error : null }"
       >
        <label class="gavc-label" for="edit-entreprise_cedee-modifyEntrepriseCedeeProcedureCollective-input">
          L'entreprise fait-elle l’objet d’une procédure collective ?
          <span class="gavc-required"> * </span>
        </label>
        <select
          class="gavc-select"
          v-model="modifyEntrepriseCedeeProcedureCollective"
          name="edit-entreprise_cedee-modifyEntrepriseCedeeProcedureCollective-input"
        >
          <option v-for="option in procedureCollectiveOptions" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
        <i
          v-b-tooltip.hover.right="{ variant: 'light' }"
          title="Si votre entreprise fait face à des difficultés, sélectionnez dans la liste déroulante le le type de procédure collective qui vous concerne.
                 Cette information ne sera pas affichée sur votre annonce de cession. Elle sera uniquement communiquée au candidat repreneur que vous avez identifié. "
          class="ml-2 gavc-text-martinique gavc-icon gavc-icon-bulb">
        </i>
      </div>

      <div
         class="gavc-field">
        <label class="gavc-label" for="edit-annonce-modifyTypeLocaux-input">
          Vous êtes :
          <span class="gavc-required"> * </span>
        </label>
        <select class="gavc-select" v-model="modifyTypeLocaux" name="edit-annonce-modifyTypeLocaux-input">
          <option v-for="option in typeLocauxExploitantOptions" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': modifyEntrepriseCedeeZipCode && $v.modifyEntrepriseCedeeZipCode.$dirty ? $v.modifyEntrepriseCedeeZipCode.$error : null }"
          >
            <label class="gavc-label" for="edit-entreprise_cedee-modifyEntrepriseCedeeZipCode-group">
              Code postal :
              <span class="gavc-required"> * </span>
            </label>
            <input
              class="gavc-input"
              id="edit-entreprise_cedee-modifyEntrepriseCedeeZipCode-group"
              maxlength="5"
              @input="$v.modifyEntrepriseCedeeZipCode.$touch()"
              type="text"
              v-model="modifyEntrepriseCedeeZipCode"
            >
            <inputErrorValidationMessage
              v-if="modifyEntrepriseCedeeZipCode && $v.modifyEntrepriseCedeeZipCode.$invalid"
              message="Le code postal doit être composé de 5 chiffres"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': modifyEntrepriseCedeeCity && $v.modifyEntrepriseCedeeCity.$dirty ? $v.modifyEntrepriseCedeeCity.$error : null }"
          >
            <label class="gavc-label" for="edit-entreprise_cedee-modifyEntrepriseCedeeCity-group">
              Ville :
              <span class="gavc-required"> * </span>
            </label>
            <input
              class="gavc-input"
              id="edit-entreprise_cedee-modifyEntrepriseCedeeCity-group"
              maxlength="128"
              @input="$v.modifyEntrepriseCedeeCity.$touch()"
              type="text"
              v-model="modifyEntrepriseCedeeCity"
            >
            <inputErrorValidationMessage
              v-if="modifyEntrepriseCedeeCity && $v.modifyEntrepriseCedeeCity.$invalid"
              message="La ville doit comporter entre 2 et 128 caractères"
            />
          </div>
        </div>
      </div>

      <hr>

      <p class="gavc-chapo">Votre annonce :</p>
      <div
        class="gavc-field mt-2"
        v-bind:class="{ 'is-error': modifyTitle && $v.modifyTitle.$dirty ? $v.modifyTitle.$error : null  }">
        <label class="gavc-label" for="edit-annonce-modifyTitle-group">
            Le titre de votre annonce est :
            <span class="gavc-required"> * </span>
        </label>
        <input
          class="gavc-input"
          id="edit-annonce-modifyTitle-group"
          maxlength="100"
          placeholder="Ma société à vendre"
          @input="$v.modifyTitle.$touch()"
          type="text"
          v-model="modifyTitle"
        >
        <inputErrorValidationMessage
          v-if="modifyTitle && $v.modifyTitle.$invalid"
          message="Le titre de votre annonce doit comporter entre 2 et 100 caractères"
        />
      </div>

      <div class="gavc-field"
           v-bind:class="{ 'is-error': modifyActivite && $v.modifyActivite.$dirty ? $v.modifyActivite.$error : null  }">
        <label class="gavc-label" for="edit-annonce-modifyActivite-group">
          Décrivez l'activité de votre entreprise que vous souhaitez céder :
          <span class="gavc-required"> * </span>
        </label>
        <textarea
          class="gavc-textarea"
          id="edit-annonce-modifyActivite-group"
          maxlength="1000"
          placeholder="Décrivez votre activité"
          @input="$v.modifyActivite.$touch()"
          type="text"
          v-model="modifyActivite">
        </textarea>
        <inputErrorValidationMessage
          v-if="modifyTitle && $v.modifyTitle.$invalid"
          message="La description de votre activité doit comporter entre 2 et 1000 caractères"
        />
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div
             class="gavc-field"
             v-bind:class="{ 'is-error': modifyPriceCategory && $v.modifyPriceCategory.$dirty ? $v.modifyPriceCategory.$error : null }"
           >
            <label class="gavc-label" for="edit-annonce-modifyPriceCategory-input">
              Tranche de votre prix de cession :
              <span class="gavc-required"> * </span>
            </label>
            <select
              class="gavc-select"
              v-model="modifyPriceCategory"
              name="edit-annonce-modifyPriceCategory-input"
            >
              <option v-for="option in priceCategoryOptions" :key="option.value" class="gavc-select-option" :value="option.value">
                {{option.text}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <label class="gavc-label" for="registration-step-annonce-modifyPrice-input">
              Prix de cession affiché :
          </label>
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': modifyPrice && $v.modifyPrice.$dirty ? $v.modifyPrice.$error : null  }"
          >
           <div class="gavc-field gavc-field--icon">
             <numberMoneyInput
               ref="editAnnoncePriceNumberInput"
               id="edit-annonce-price-input"
               :maxLength="'10'"
               :maskedOptions="maskedMontantOptions"
               :value="modifyPrice"
               @value-changed="modifyPrice = $event"
               @input="$v.modifyPrice.$touch()"
             />
             <i class="gavc-icon gavc-icon-euro" ></i>
           </div>
           <small class="gavc-text-martinique">Si non connu, remplir uniquement la tranche de votre prix de cession</small>
         </div>
        </div>
        <div class="col-12 col-md-6">

         <p class="mt-3 gavc-chapo">
            💵 Prix proposé et affiché sur l'annonce :<br>
            <span v-if="modifyPriceCategory">
              {{priceCategoryRender(modifyPriceCategory)}}
            </span>
            <span v-if="modifyPrice">
               <br>Prix exact : {{formatThousandSeparatorNumber(modifyPrice)}} €
            </span>

          </p>
        </div>
      </div>



      <div class="mt-3">
        <helpingPrixCessionSidebar />
      </div>

      <hr>

      <div
         class="gavc-field"
         v-bind:class="{ 'is-error': modifyCaCategory && $v.modifyCaCategory.$dirty ? $v.modifyCaCategory.$error : null }"
       >
        <label class="gavc-label" for="edit-annonce-modifyCaCategory-input">
          Dernier CA ou moyenne des trois derniers CA :
          <span class="gavc-required"> * </span>
        </label>
        <select
          class="gavc-select"
          v-model="modifyCaCategory"
          name="edit-annonce-modifyCaCategory-input"
        >
          <option v-for="option in caCategoryOptions" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
      </div>


      <div
         class="gavc-field"
         v-bind:class="{ 'is-error': modifyNbSal && $v.modifyNbSal.$dirty ? $v.modifyNbSal.$error : null  }">
        <label class="gavc-label" for="edit-annonce-modifyNbSal-input">
            Nombre de salariés :
            <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-field gavc-field--icon">
          <input class="gavc-input"
                 @input="$v.modifyNbSal.$touch()"
                 id="edit-annonce-modifyNbSal-input"
                 placeholder="XX"
                 type="text"
                 maxlength="6"
                 v-model.lazy="maskedNbSalaries"
                 v-money="maskedMontantOptions">
        </div>
      </div>


      <p class="gavc-label mt-3"
          v-bind:class="{ 'is-error': modifyImage && $v.modifyImage.$dirty ? $v.modifyImage.$error : null  }">
          Image de votre annonce (5 maximum)
          <span class="gavc-required"> * </span>
      </p>
      <div class="row mt-4">
        <div class="col-12 col-md-4 text-center">

          <div v-if="annonce.images.length < 5">
            <div class="gavc-upload ">
              <label for="input-upload">
              </label>
              <i class="gavc-icon gavc-icon-camera">
              </i>
              <p class="gavc-question">
                Ajouter une photo
              </p>
              <input
                class="gavc-upload__input"
                type="file"
                id="input-upload"
                required
                @change="createAnnonceImage">
            </div>
          </div>
          <p class="gavc-p gavc-text-rose" v-if="modifyImage && !$v.modifyImage.image_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</p>
          <p class="gavc-p gavc-text-rose" v-if="modifyImage && !$v.modifyImage.image_extension_validation">Attention, votre fichier doit être au format jpg, jpeg ou png</p>
          <p class="gavc-p gavc-text-rose" v-if="modifyImage && !$v.modifyImage.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</p>
          <p class="gavc-p gavc-text-rose" v-if="modifyImage && !$v.modifyImage.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</p>
        </div>
        <div class="col-12 col-md-8 text-center mt-2 mt-md-0">
          <div
            v-if="annonce.images.length > 0"
            class="row"
          >
            <div
              v-for="annonceImage in annonce.images"
              :key="annonceImage.id"
              class="col-12 col-lg-4 col-md-6"
            >
              <div class="gavc-upload gavc-upload--active">
                <figure class="gavc-upload__img">
                  <img v-if="annonceImage" :src="imageUrl(annonceImage)" class="gavc-img-cover"  alt="Image upload">
                  <img v-else :src="imageUrl(annonceImage)" class="gavc-img-cover"  alt="Image upload">
                </figure>
                <button @click="removeImage(annonceImage)" class="gavc-btn-close">
                  <i class="gavc-icon gavc-icon-cross"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            v-else
            class="row"
          >
            <div
              class="col-12"
            >
              <div class="gavc-upload gavc-upload--active">
                <figure class="gavc-upload__img">
                  <img :src="defaultImageUrl" class="gavc-img-cover"  alt="Image upload">
                </figure>
              </div>
            </div>
          </div>

        </div>
      </div>

      <hr>

      <div class="gavc-field"
           v-bind:class="{ 'is-error': modifyInformation && $v.modifyInformation.$dirty ? $v.modifyInformation.$error : null  }">
        <label class="gavc-label" for="edit-annonce-modifyInformation-group">
          Informations complémentaires que vous souhaitez afficher sur votre annonce
          <i
            class="gavc-text-martinique gavc-icon gavc-icon-bulb text-left"
            v-b-tooltip.hover.html.right="{ variant: 'light' }"
            :title="`Vous pouvez préciser certaines informations : </br> 
            Par exemple, s'agit-il : <br/>
            - d'une vente ou d'une location de fonds ou de parts sociales d'une activité commerciale ou de services à la personne ?</br>
            - d'une vente ou d'une location de fonds ou de parts sociales d'une activité artisanale ?</br>
            - d'une vente ou d’une location de fonds ou de parts sociales d'un hôtel, bar, restaurant, hébergement touristique ?</br>
            - d'une vente de droit au bail, pas de porte ou d'une location de murs commerciaux ?</br>
            - d'une vente de PME industrielle ou de services à l'entreprise ?`">
          </i>
        </label>
        <textarea
          class="gavc-textarea"
          id="edit-annonce-modifyInformation-group"
          maxlength="1000"
          placeholder="Informations complémentaires"
          @input="$v.modifyInformation.$touch()"
          type="text"
          v-model="modifyInformation">
        </textarea>
      </div>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': modifyWebsite && $v.modifyWebsite.$dirty ? $v.modifyWebsite.$error : null  }">
        <label class="gavc-label" for="registration-step-annonce-website-group">
            Site web de votre entreprise (ou page Facebook, Intagram, etc.) :
        </label>
        <input
          class="gavc-input"
          id="edit-annonce--website"
          maxlength="1000"
          placeholder="https://mon-entreprise-a-ceder.fr"
          @input="$v.modifyWebsite.$touch()"
          type="text"
          v-model="modifyWebsite"
        >
        <inputErrorValidationMessage
          v-if="modifyWebsite && $v.modifyWebsite.$invalid"
          message="Veuillez renseigner une url valide"
        />
      </div>

      <div class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn float-right "
            @click="publishAnnonce(true)"
            :disabled="isInvalidAnnonce">
              Publier mon annonce
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { apiBaseUrl} from '@/variables/localVariables'
import { validationMixin } from 'vuelidate'
import { integer, required, minValue, maxValue, minLength, maxLength, numeric, url} from 'vuelidate/lib/validators'

import { APICedant } from '@/api/APICedant'
import renderMixins from "@/mixins/renderMixins"
import { image_size_validation, image_extension_validation } from '@/validators/validators'
import { caCategoryList } from '@/variables/caCategory'
import { formeSocialeList } from '@/variables/formeSociale'
import { defaultMoneyMaskOptions } from "@/variables/masks/moneyMasks"
import { priceCategoryList } from '@/variables/priceCategory'
import { typeLocauxExploitantList } from '@/variables/typeLocauxExploitantList'
import { procedureCollectiveList } from '@/variables/procedureCollective'

const apiCedantService = new APICedant()
const annonceItem = () => import('@/components/annonce/annonceItem')
const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')
const helpingPrixCessionSidebar = () => import('@/components/informations/helpingPrixCessionSidebar')
const numberMoneyInput = () => import('@/components/inputs/numberMoneyInput')


export default {
  name: 'EditAnnonce',
  components: {
    annonceItem,
    helpingPrixCessionSidebar,
    inputErrorValidationMessage,
    numberMoneyInput
  },
  mixins: [
    renderMixins,
    validationMixin
  ],
  data: function () {
    return {
      modifyActivite: '',
      modifyCaCategory: '',
      modifyImage: null,
      modifyInformation: '',
      modifyNbSal: '',
      modifyPrice: null,
      modifyPriceCategory: null,
      modifyTitle: '',
      modifyTypeLocaux: '',
      modifyWebsite: '',

      modifyEntrepriseCedeeCity: '',
      modifyEntrepriseCedeeName: '',
      modifyEntrepriseCedeeFormeSociale: '',
      modifyEntrepriseCedeeZipCode: '',
      modifyEntrepriseCedeeProcedureCollective: "",

      annonce: {
        activite: '',
        ca: '',
        ca_category: "",
        image: "",
        information: "",
        nb_sal: "",
        offre_cession: {
          entreprise_cedee: {
            city: "",
            cedant: {
              completed_rate: 0,
            },
            completed_rate: 0,
            forme_sociale: "",
            name: "",
            procedure_collective: "",
            zip_code: "",
          }
        },
        price: null,
        price_category: null,
        title: "",
        type_locaux_exploitation: "",
        website: ""
      },

      typeLocauxExploitantOptions: typeLocauxExploitantList,
      maskedMontantOptions: defaultMoneyMaskOptions,

      maskedNbSalaries: 0,

      caCategoryOptions: caCategoryList,
      formeSocialeOptions: formeSocialeList,
      procedureCollectiveOptions: procedureCollectiveList,
      priceCategoryOptions: priceCategoryList,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidAnnonce () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    },
    defaultImageUrl () {
      return require('@/assets/images/default.jpg')
    }
  },
  watch: {
    maskedNbSalaries(value) {
      this.modifyNbSal = value.replace(/ /g,'')
    },
    modifyPrice (value) {
      if (value === 0) {
        return null
      }
      if (value >= 10000000) {
        this.modifyPriceCategory = '10000_'
        return true
      }
      else {
        this.priceCategoryOptions.forEach((item) => {
          let min_max = item.value.split("_")
          if (value >= Number(min_max[0]) * 1000 && value < Number(min_max[1]) * 1000) {
            this.modifyPriceCategory = item.value
            return true
          }
        })
      }
    }
  },
  created: function() {
    this.getAnnonce()
  },
  validations: {
    modifyActivite: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },
    modifyCaCategory: {
      required
    },
    modifyInformation: {
      minLength: minLength(2),
      maxLength: maxLength(1000)
    },
    modifyNbSal: {
      required,
      integer,
      minValue: minValue(0),
    },
    modifyPrice: {
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(100000000000),
    },
    modifyPriceCategory: {
      required
    },
    modifyTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100)
    },
    modifyTypeLocaux: {
      required
    },
    modifyImage: {
      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      },
      image_size_validation,
      image_extension_validation,
    },
    modifyWebsite: {
      minLength: minLength(2),
      maxLength: maxLength(1000),
      url
    },

    modifyEntrepriseCedeeCity: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(127)
    },
    modifyEntrepriseCedeeFormeSociale: {
      required,
    },
    modifyEntrepriseCedeeName: {
      minLength: minLength(2),
      maxLength: maxLength(255),
      required
    },
    modifyEntrepriseCedeeZipCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      integer
    },
    modifyEntrepriseCedeeProcedureCollective: {
      required,
    }
  },
  methods: {
    createAnnonceImage (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return null
      this.modifyImage = files[0]
      if (this.$v.modifyImage.$invalid) {
        return null
      }
      else {
        this.$store.commit('loadFromAPI')
        let formData = new FormData()
        formData.append('file', this.modifyImage)
        apiCedantService.createAnnonceImage(this.token, this.$route.params.annonceId, formData)
        .then((result) => {
          this.annonce.images.push(result.data)
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('EditAnnonceImage createAnnonceImage API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
      }
    },
    getAnnonce () {
      this.$store.commit('loadFromAPI')
      apiCedantService.getAnnonce(this.token, this.$route.params.annonceId)
      .then((result) => {
        this.annonce = result.data
        this.modifyActivite = result.data.activite
        this.modifyCaCategory = result.data.ca_category
        this.modifyInformation = result.data.information
        this.modifyNbSal = Number(result.data.nb_sal)
        this.modifyPrice = Number(result.data.price)
        this.modifyPriceCategory = result.data.price_category
        this.modifyTitle = result.data.title
        this.modifyTypeLocaux = result.data.type_locaux_exploitation
        this.modifyWebsite = result.data.website || ""

        this.modifyEntrepriseCedeeCity = result.data.offre_cession.entreprise_cedee.city
        this.modifyEntrepriseCedeeFormeSociale = result.data.offre_cession.entreprise_cedee.forme_sociale
        this.modifyEntrepriseCedeeName = result.data.offre_cession.entreprise_cedee.name
        this.modifyEntrepriseCedeeZipCode = result.data.offre_cession.entreprise_cedee.zip_code
        this.modifyEntrepriseCedeeProcedureCollective = result.data.offre_cession.entreprise_cedee.procedure_collective

        this.maskedNbSalaries = this.modifyNbSal
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('EditAnnonce getAnnonce API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    imageUrl (image) {
      return apiBaseUrl + image.file
    },

    removeImage (annonceImage) {
      this.$store.commit('loadFromAPI')
      apiCedantService.deleteAnnonceImage(this.token, annonceImage.id)
      .then(() => {
        this.annonce.images = this.annonce.images.filter((image) => image.id !== annonceImage.id)
        this.$bvToast.toast("L'image de votre annonce a bien été supprimée", {
          title: 'Image supprimée',
          variant: 'warning',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('EditAnnonce removeImage API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    publishAnnonce (publicationStatus) {
      this.$store.commit('loadFromAPI')
      const data = {

        is_published:  publicationStatus,
        activite: this.modifyActivite,
        ca_category: this.modifyCaCategory,
        information: this.modifyInformation,
        nb_sal: this.modifyNbSal,
        price: this.modifyPrice,
        price_category: this.modifyPriceCategory,
        title: this.modifyTitle,
        type_locaux_exploitation: this.modifyTypeLocaux,
        website: this.modifyWebsite,

        entreprise_cedee_name: this.modifyEntrepriseCedeeName,
        entreprise_cedee_forme_sociale: this.modifyEntrepriseCedeeFormeSociale,
        entreprise_cedee_city: this.modifyEntrepriseCedeeCity,
        entreprise_cedee_zip_code: this.modifyEntrepriseCedeeZipCode,
        entreprise_cedee_procedure_collective: this.modifyEntrepriseCedeeProcedureCollective,
      }

      apiCedantService.modifyAnnonce(this.token, this.$route.params.annonceId, data)
      .then((result) => {
        if (!publicationStatus) {
          this.$bvToast.toast('Votre annonce a bien été retirée', {
            title: 'Offre de cession retirée',
            variant: 'danger',
            solid: true
          })
        }
        else {
          if (!this.annonce.is_validated) {
            this.$root.$bvToast.toast('Félicitations, votre annonce a été publiée et est en cours de validation par nos équipes', {
              title: 'Offre de cession en attente de validation',
              variant: 'success',
              solid: true
            })
            this.$router.push('/cedant/tableau-de-bord')
          }
          else {
            this.$bvToast.toast('Félicitations, votre annonce a bien été publiée', {
              title: 'Offre de cession publiée',
              variant: 'success',
              solid: true
            })
          }
        }
        this.annonce = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('EditOffreCession publishAnnonce API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>

<style>
.dragdrop .custom-file,
.dragdrop .custom-file-input {
  height: 100px;
}

.dragdrop .custom-file-label {
  background-color: #e5d7c7;
  border: 0;
  border: 2px dotted;
  height: 100px;
  line-height: 90px;
  text-align: center;
  padding: 0;
}

.dragdrop .custom-file:hover .custom-file-label {
  background: #FFE7D1;
}

.dragdrop .custom-file-label::after {
  display: none;
}

.tooltip-inner {
  text-align: left !important;
}
</style>
