export const priceCategoryList = [
  {
    "value": "0_50",
    "text": "De 0€ à 50 K€"
  },
  {
    "value": "50_100",
    "text": "De 50 K€ à 100 K€"
  },
  {
    "value": "100_150",
    "text": "De 100 K€ à 150 K€​"
  },
  {
    "value": "150_200",
    "text": "De 150 K€ à 200 K€​"
  },
  {
    "value": "200_300",
    "text": "De 200 K€ à 300 K€"
  },
  {
    "value": "300_500",
    "text": "De 300 K€ à 500 K€"
  },
  {
    "value": "500_750",
    "text": "De 500 K€ à 750 K€"
  },
  {
    "value": "750_1000",
    "text": "De 750 K€ à 1 M€"
  },
  {
    "value": "1000_2000",
    "text": "De 1 M€ à 2 M€"
  },
  {
    "value": "2000_5000",
    "text": "De 2 M€ à 5 M€"
  },
  {
    "value": "5000_10000",
    "text": "De 5 M€ à 10 M€"
  },
  {
    "value": "10000_",
    "text": "Supérieur à 10 M€"
  }

]
